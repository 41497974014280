/**
 * Created by ZengFanhui on 2020/10/26 9:40
 */
export const subMenuList = state => state.subMenuList

export const subMenuActiveName = state => state.subMenuActiveName

export const menuActiveName = state => state.menuActiveName

export const menuList = state => state.menuList

export const userInfo = state => state.userInfo

export const currentAuth = state => state.currentAuth
