/**
 * Created by ZengFanhui on 2020/10/26 9:41
 */
import * as types from './mutations-types'

export const clearAllData = function ({ commit }) {
  commit(types.SET_MENU_LIST, [])
  commit(types.SET_MENU_ACTIVE_NAME, '')
  commit(types.SET_SUB_MENU_ACTIVE_NAME, '')
  commit(types.SET_SUB_MENU_LIST, [])
  commit(types.SET_USERINFO, {})
  commit(types.SET_CURRENT_AUTH, [])
}
