/**
 * Created by ZengFanhui on 2020/10/26 9:52
 */
import axios from 'axios'
import router from '../router'
import qs from 'qs'
import { Message, Loading } from 'element-ui'

let loadingInstance

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
})

/**
 * 请求拦截
 */
http.interceptors.request.use(config => {
  if (config.showLoading) {
    loadingInstance = Loading.service({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(243, 243, 243, 0.1)'
    })
  }
  return config
}, error => {
  return Promise.reject(error)
})

/**
 * 响应拦截
 */
http.interceptors.response.use(response => {
  if (response.data && response.data.result === 3) { // 登录超时
    router.push({name: 'login'})
  }
  if (loadingInstance) {
    loadingInstance.close()
  }
  if (response.data && (response.data.result === 1 || response.data.result === 2)) {
    Message.error(response.data.msg)
    throw Error(response.data.msg)
  }
  return response.data
}, error => {
  if (loadingInstance) {
    loadingInstance.close()
  }
  Message.error('服务器错误，请联系管理员')
  return Promise.reject(error)
})

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // 非生产环境
  return process.env.VUE_APP_URL + actionName
}

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  let defaults = {}
  return openDefultParams ? Object.assign({}, defaults, params) : params
}

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, contentType = 'json') => {
  if (contentType === 'form') {
    http.defaults.headers['Content-Type'] = 'multipart/form-data'
    return data
  }
  return contentType === 'json' ? JSON.stringify(data) : qs.stringify(data)
}

export default http
