import Vue from 'vue'
import Router from 'vue-router'
import { insertStr2LowCase } from '../utils/common'
const _import = require('./_import_' + process.env.NODE_ENV)

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = Router.prototype.replace
Router.prototype.replace = function replase(location) {
  return originalReplace.call(this, location).catch(err => err)
}
Vue.use(Router)

// 全局路由(无需嵌套上左右整体布局)
const globalRoutes = [
  { path: '/404', component: _import('error/404'), name: '404', meta: { title: '404未找到' } },
  { path: '/login', component: _import('login/index'), name: 'login', meta: { title: '肉品追溯管理后台' } }
]

const mainRoutes = {
  path: '/',
  component: _import('main/index'),
  meta: { title: '肉品追溯管理后台' },
  children: [
    // { path: '/index', name: 'index', component: _import('index/index'), meta: { title: '欢迎' } }
  ],
  beforeEnter (to, from, next) {
    let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (!userInfo) {
      next({name: 'login'})
    }
    next()
  }
}

const router = new Router({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  base: process.env.VUE_APP_BASE_PATH,
  isAddDynamicMenuRoutes: false, // 是否已经添加动态(菜单)路由
  routes: globalRoutes.concat(mainRoutes)
})

router.beforeEach((to, from, next) => {
  // 添加动态(菜单)路由
  // 1. 已经添加 or 全局路由, 直接访问
  // 2. 获取菜单列表, 添加并保存本地存储
  const loginUser = JSON.parse(sessionStorage.getItem('userInfo'))
  if (loginUser && ((to.path === '/login' && from.path === '/'))) {
    next({path: from.path})
  }

  if (router.options.isAddDynamicMenuRoutes || fnCurrentRouteType(to) === 'global') {
    next()
  } else {
    const menuList = JSON.parse(sessionStorage.getItem('menuList'))
    fnAddDynamicMenuRoutes(menuList)
    router.options.isAddDynamicMenuRoutes = true
    next({ ...to, replace: true })
  }
})

/**
 * 判断当前路由类型, global: 全局路由, main: 主入口路由
 * @param {*} route 当前路由
 */
function fnCurrentRouteType (route) {
  let temp = []
  for (let i = 0; i < globalRoutes.length; i++) {
    if (route.path === globalRoutes[i].path) {
      return 'global'
    } else if (globalRoutes[i].children && globalRoutes[i].children.length >= 1) {
      temp = temp.concat(globalRoutes[i].children)
    }
  }
  return temp.length >= 1 ? fnCurrentRouteType(route, temp) : 'main'
}

function fnAddDynamicMenuRoutes (menuList = [], routes = []) {
  for (let i in menuList) {
    const subMenuList = menuList[i].subMenu
    for (let j in subMenuList) {
      if (/\S/.test(subMenuList[j].url)) {
        subMenuList[j].url = subMenuList[j].url.replace(/^\//, '')
        let route = {
          path: subMenuList[j].url,
          component: _import(insertStr2LowCase(subMenuList[j].url)) || null,
          name: subMenuList[j].url.replace('/', '-'),
          meta: {
            id: subMenuList[j].id,
            title: subMenuList[j].name,
            menuName: menuList[i].name,
            isDynamic: true
          }
        }
        routes.push(route)
      }
    }
  }

  mainRoutes.name = 'main-dynamic'
  mainRoutes.children = routes
  router.addRoutes([
    mainRoutes,
    { path: '*', redirect: { name: 'login' } }
  ])
  sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(mainRoutes.children || '[]'))
}

export default router
