/**
 * Created by ZengFanhui on 2020/10/26 9:52
 */
import http from './httpRequest'

const _import = require('../router/_import_' + process.env.NODE_ENV)

/**
 * 根据当前路由动态为当前路由添加子路由
 * @param router
 * @param currentRoute
 */
export function dynamicChangeRoute(router, currentRoute) {
  let currentRouteName = currentRoute.name
  let currentRoutePath = currentRoute.path

  let route = {
    path: `${currentRoutePath}/:id`,
    component: _import(`${insertStr2LowCase(currentRoutePath).replace(/^\//, '')}-detail`),
    name: `${currentRouteName.replace('/', '-')}-detail`,
    meta: {
      id: currentRoute.meta.id,
      title: currentRoute.meta.title,
      menuName: currentRoute.meta.menuName,
      isDynamic: true
    }
  }

  let dynamicMenuRoutes = JSON.parse(sessionStorage.getItem('dynamicMenuRoutes'))

  let isRouteExists = dynamicMenuRoutes.filter(item => {
    return item.path === route.path
  })

  if (isRouteExists.length === 0) {
    dynamicMenuRoutes.push(route)
  }

  sessionStorage.setItem('dynamicMenuRoutes', JSON.stringify(dynamicMenuRoutes || '[]'))
  const mainRoutes = {
    path: '/',
    component: _import('main/index'),
    meta: {title: '主入口整体布局'},
    children: dynamicMenuRoutes
  }
  //
  return mainRoutes
}

/**
 * 判断对象是否为空
 * @param obj
 * @return {boolean}
 */
export function isObjectEmpty(obj) {
  let arr = Object.keys(obj)
  if (arr.length === 0) {
    return true
  }
  return false
}

/**
 * 网络请求
 * @param url
 * @param method
 * @param data
 * @return {*}
 */
export function doHttp(url, method, data, type, showLoading = true) {
  let obj = {
    url: http.adornUrl(url),
    method,
    showLoading
  }

  if (method === 'get' || method === 'GET') {
    obj.params = http.adornParams(data)
  } else if (method === 'post' || method === 'POST') {
    obj.data = http.adornData(data, type)
  }

  return http(obj)
}

/**
 * 转换菜单结构
 * @param data
 * @return {Array}
 */
export function menuDataTranslate(data, mainName) {
  let menuList = []
  for (let i = 0; i < data.length; i++) {
    let item = data[i]
    let menu = {
      id: item.id,
      name: item.name,
      sort: item.sort,
      icon: item.icon,
      url: item.url
    }

    menu.subMenu = (item.children && item.children.length !== 0) ? menuDataTranslate(item.children, item.name) : []

    if (mainName) {
      menu.mainName = mainName
    }
    menuList.push(menu)
  }
  // 排序后再返回
  menuList.sort((n, m) => {
    return n.sort - m.sort
  })
  return menuList
}

/**
 * 将字符串中大写字母前加上'-'，并将大写转小写返回
 * @example 'abcdEfgHi' -> 'abcd-efg-hi'
 * @param str
 * @return {*}
 */
export function insertStr2LowCase(str) {
  const regex = /[A-Z]/g
  let index = str.search(regex)
  let result = str
  if (index !== -1) {
    result = str.slice(0, index) + '-' + str.slice(index, index + 1).toLowerCase() + str.slice(index + 1)
  }

  let isHaveUpperCase = result.search(regex)
  if (isHaveUpperCase === -1) {
    return result
  } else {
    return insertStr2LowCase(result)
  }
}

/**
 * 格式化日期
 * @param date
 * @param fmt
 * @return {*}
 */
export function formatDate(date, fmt = 'YYYY-MM-DD HH:mm:ss') {
  if (!date) {
    return ''
  }
  if (typeof date === 'string') {
    date = new Date(date.replace(/-/g, '/'))
  }
  if (typeof date === 'number') {
    date = new Date(date)
  }
  let o = {
    'M+': date.getMonth() + 1,
    'D+': date.getDate(),
    'h+': date.getHours() % 12 === 0 ? 12 : date.getHours() % 12,
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
    'q+': Math.floor((date.getMonth() + 3) / 3),
    'S': date.getMilliseconds()
  }
  let week = {
    '0': '\u65e5',
    '1': '\u4e00',
    '2': '\u4e8c',
    '3': '\u4e09',
    '4': '\u56db',
    '5': '\u4e94',
    '6': '\u516d'
  }
  if (/(Y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  }
  if (/(E+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, ((RegExp.$1.length > 1) ? (RegExp.$1.length > 2 ? '\u661f\u671f' : '\u5468') : '') + week[date.getDay() + ''])
  }
  for (let k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) {
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
    }
  }
  return fmt
}

/**
 * 计算日期相差天数
 * @param sDate1
 * @param sDate2
 * @return {Number|*}
 */
export function dateDiff(sDate1, sDate2) {
  let oDate1
  let oDate2
  let iDays
  oDate1 = new Date(sDate1)
  oDate2 = new Date(sDate2)
  iDays = parseInt((oDate1 - oDate2) / 1000 / 60 / 60 / 24) // 把相差的毫秒数转换为天数
  return iDays
}

/**
 * 获取传入日期所在月份的天数
 * @param date
 */
export function monthDays(date) {
  date = new Date(date)
  let fullYear = date.getFullYear()
  let month = date.getMonth() + 1
  let d = new Date(fullYear, month, 0)
  return d.getDate()
}

/**
 * 获取指定日期之后N天的日期
 * @param date
 */
export function laterDate(date, num) {
  date = new Date(date)
  let laterDate = new Date(date.setDate(date.getDate() + num))
  return laterDate
}

/**
 * 去除空格
 * @param str
 */
export function strTrim(str) {
  str = str.toString()
  return str.replace(/^\s+|\s+$/gm, '')
}

/**
 * 对象复制
 * @param source
 * @param target
 */
export function copyProperties(source, target) {
  if (!source || isObjectEmpty(source)) return
  if (!target || isObjectEmpty(target)) return
  Object.keys(target).forEach(item => {
    target[item] = source[item]
  })
  return target
}

/**
 * 获取文件后缀
 * @param fileName
 * @returns {{}}
 */
export function handleFileExtByName(fileName) {
  let fileInfo = {}
  const fileSplitArr = fileName.split('.')
  const fileExt = fileSplitArr[fileSplitArr.length - 1].toLowerCase()
  if (fileExt === 'jpg' || fileExt === 'jpeg' || fileExt === 'png' || fileExt === 'gif' || fileExt === 'bmp') {
    fileInfo.isImage = true
  } else {
    fileInfo.isImage = false
  }
  fileInfo.ext = fileExt
  return fileInfo
}
