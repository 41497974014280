/**
 * Created by ZengFanhui on 2020/10/26 9:33
 */
/**
 * Created by ZengFanHui on 18/05/04.
 */
export const SET_MENU_ACTIVE_NAME = 'SET_MENU_ACTIVE_NAME'

export const SET_SUB_MENU_LIST = 'SET_SUB_MENU_LIST'

export const SET_SUB_MENU_ACTIVE_NAME = 'SET_SUB_MENU_ACTIVE_NAME'

export const SET_MENU_LIST = 'SET_MENU_LIST'

export const SET_USERINFO = 'SET_USERINFO'

export const SET_CURRENT_AUTH = 'SET_CURRENT_AUTH'
