/**
 * Created by ZengFanhui on 2020/10/26 9:34
 */
import * as types from './mutations-types'

const mutations = {
  [types.SET_SUB_MENU_LIST] (state, subMenuList) {
    state.subMenuList = subMenuList
  },
  [types.SET_SUB_MENU_ACTIVE_NAME] (state, name) {
    state.subMenuActiveName = name
  },
  [types.SET_MENU_ACTIVE_NAME] (state, name) {
    state.menuActiveName = name
  },
  [types.SET_MENU_LIST] (state, list) {
    state.menuList = list
  },
  [types.SET_USERINFO] (state, userInfo) {
    state.userInfo = userInfo
  },
  [types.SET_CURRENT_AUTH] (state, authList) {
    state.currentAuth = authList
  }
}

export default mutations
