/**
 * Created by ZengFanhui on 2020/10/26 9:29
 */
const state = {
  menuActiveName: '', // 主菜单选中名称
  subMenuList: [], // 二级菜单列表
  subMenuActiveName: '', // 二级菜单选中名称
  menuList: [], // 菜单列表
  userInfo: {}, // 当前用户信息
  currentAuth: [] // 用户页面用户权限
}

export default state
